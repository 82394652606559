<template>
  <Modal
    :show="isShow"
    :title="title"
    :errors="errors"
    full
    @cansel="$emit('close')"
    @ok="handlerSubmit"
  >
    <div class="presetio-modals-modificated" ref="formContainer">
      <CRow>
        <CCol sm="6" v-for="lang in languages" :key="`name_${lang}`">
          <CInput
            v-model="form[`name_${lang}`]"
            :label="`Name  ${lang}`"
            :placeholder="`Name ${lang}`"
          />
        </CCol>

        <!-- <CCol sm="6">
          <CInput v-model="form.created_at" label="Created At" placeholder="Created At" />
        </CCol>-->

        <CCol sm="6">
          <AppSlect v-model="form.type" :options="typeOptions" label="Type" />
        </CCol>

        <CCol sm="6">
          <CInput v-model="form.order" label="Order" placeholder="Order" />
        </CCol>

        <CCol sm="12">
          <CInputCheckbox
            style="margin: 1rem 0"
            label="Show"
            :checked="form.show"
            @update:checked="value => (form.show = value)"
          />
        </CCol>

        <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="form.image && form.image.file"
            name="image"
            :isShow="isShow"
            :image="form.image && form.image.url ? form.image.url : form.image"
            @input="value => handleChangeFile({ file: value, key: 'image' })"
          />
        </CCol>

        <CCol sm="6">
          <FileLoader
            class="presetio-modals-modificated__preset"
            v-if="isShow"
            :value="form.preview && form.preview.file"
            name="Preview"
            :isShow="isShow"
            :image="
              form.preview && form.preview.url ? form.preview.url : form.preview
            "
            @input="value => handleChangeFile({ file: value, key: 'preview' })"
          />
        </CCol>
      </CRow>
    </div>
  </Modal>
</template>

<script>
import moment from "moment";

import MixinsScreenModal from "@/mixins/screenModal";

import FileLoader from "@/components/root/FileLoader";

export default {
  name: "NumberModalsModificated",

  mixins: [MixinsScreenModal],

  components: {
    FileLoader
  },

  data: () => ({
    form: {
      image: [],
      preview: null,
      type: null,
      show: true,
      order: 0,

      // created_at: null
    },

    languages: ["en", "ru", "fr", "es", "de", "it"],

    types: ["9/16", "1/1", "4/3", "3/4"]
  }),

  computed: {
    title() {
      if (this.isShow) {
        return this.data ? "Edit Powerpoint tag" : "Add Powerpoint tag";
      }
    },

    typeOptions() {
      let res = [];

      for (let item of this.types) {
        res.push({ value: item, label: item });
      }

      return res;
    }
  },

  created() {
    for (let lang of this.languages) {
      this.form[`name_${lang}`] = null;
    }
  },

  methods: {
    handleChangeFile({ file, key }) {
      console.log("handleChangeFile");

      this.form[key] = {
        type: "upload",
        file,
        url: URL.createObjectURL(file)
      };

      // this.form[key].image = URL.createObjectURL(file);

      this.$emit("change", this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.presetio-modals-modificated {
  &__preset {
    padding: 10px;

    display: flex;
    flex-wrap: wrap;

    border: 1px solid #768192;
  }
}
</style>
